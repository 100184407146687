import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@services/auth.service';
import { NotFoundPageComponent } from '@pages/not-found-page/not-found-page.component';
import { ImageViewPageComponent } from '@pages/image-view-page/image-view-page.component';
import { ImageDetailDummyPageComponent } from '@pages/image-detail-dummy-page/image-detail-dummy-page.component';
import { RightsGuard } from '@guards/rights-guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'image-search',
    pathMatch: 'full'
  },
  {
    path: 'image-search',
    loadChildren: () => import('@modules/image-search.module').then(m => m.ImageSearchModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'image-upload',
    loadChildren: () => import('@modules/image-upload.module').then(m => m.ImageUploadModule),
    canActivate: [AuthGuard, RightsGuard]
  },
  {
    path: 'image/:id',
    loadChildren: () => import('@modules/image-detail.module').then(m => m.ImageDetailModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'status',
    loadChildren: () => import('@modules/status.module').then(m => m.StatusModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'tag-manager',
    loadChildren: () => import('@modules/tag-manager.module').then(m => m.TagManagerModule),
    canActivate: [AuthGuard, RightsGuard]
  },
  {
    path: 'image-redirect',
    component: ImageDetailDummyPageComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'view/:prefix/:imageData',
    component: ImageViewPageComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    component: NotFoundPageComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'MediaSpirit - Image search'
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
