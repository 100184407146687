import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';

@Injectable()
export class NotificationService {
    constructor(private notifier: NotifierService) { }

    info(title: any, message = '') {
        if (message != '') {
            this.notifier.notify('info', `${title}, ${message}`);
        } else {
            this.notifier.notify('info', `${title}`);
        }
    }

    success(title: any, message = '') {
        if (message != '') {
            this.notifier.notify('success', `${title}, ${message}`);
        } else {
            this.notifier.notify('success', `${title}`);
        }
    }

    warning(title: any, message = '') {
        if (message != '') {
            this.notifier.notify('warning', `${title}, ${message}`);
        } else {
            this.notifier.notify('warning', `${title}`);
        }
    }

    error(title: any, message = '') {
        if (message != '') {
            this.notifier.notify('error', `${title}, ${message}`);
        } else {
            this.notifier.notify('error', `${title}`);
        }
    }
}
