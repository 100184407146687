import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

import { ImageActions } from '@actions/image.actions';
import { ImageModel } from '@models/image';
import { ImageFulltextSearchResultModel } from '@models/imagefulltextsearchresult';
import { ActionWithPayload } from '@models/action-with-payload';

export interface ImageState {
    ids: string[];
    entities: { [id: string]: ImageModel };
    oldEntities: { [id: string]: ImageModel };
}

const initialState: ImageState = {
    ids: [],
    entities: {},
    oldEntities: {}
};

export function ImageReducer(state: ImageState = initialState, action: ActionWithPayload): ImageState {
    const tempIds = [...state.ids];
    switch (action.type) {
        case ImageActions.SEARCH_IMAGE_SUCCESS:
            const searchResultOne: ImageFulltextSearchResultModel = action.payload;

            let resultInfoOne = searchResultOne.data.reduce((resultInfoOne: any, row: any) => {
                const document: any = { ...row };
                if (resultInfoOne.ids.indexOf(row.id) === -1) {
                    resultInfoOne.ids.push(row.id);
                }
                if (document.priority == null) {
                    document.priority = {};
                }
                if (document.nlCleanUrl != null) {
                    document.nlCleanUrl = '/' + document.nlCleanUrl.substring(1);
                }
                resultInfoOne.entities[row.id] = document;
                return resultInfoOne;
            }, { ids: [], entities: {} });
            return {
                ids: resultInfoOne.ids,
                entities: resultInfoOne.entities,
                oldEntities: { ...state.entities }
            };
        case ImageActions.SEARCH_IMAGE_NEXTPAGE_SUCCESS:
            const searchResultTwo: ImageFulltextSearchResultModel = action.payload;

            let resultInfoTwo = searchResultTwo.data.reduce((resultInfoTwoData: any, row: any) => {
                const document: any = { ...row };
                if (state.ids.indexOf(row.id) === -1) {
                    resultInfoTwoData.ids.push(row.id);
                }
                if (document.priority == null) {
                    document.priority = {};
                }
                if (document.nlCleanUrl != null) {
                    document.nlCleanUrl = '/' + document.nlCleanUrl.substring(1);
                }
                resultInfoTwoData.entities[row.id] = document;
                return resultInfoTwoData;
            }, { ids: [], entities: {} });
            let newStateTwo = {
                ids: state.ids.concat(resultInfoTwo.ids),
                entities: Object.assign({}, state.entities, resultInfoTwo.entities),
                oldEntities: { ...state.entities }
            };
            return newStateTwo;
        case ImageActions.LOAD_IMAGE:
            let image: ImageModel = { ...action.payload };
            if (image.nlCleanUrl != null) {
                image.nlCleanUrl = '/' + image.nlCleanUrl.substring(1);
            }
            if (!image.focusPoint) {
                image.focusPoint = { percentageX: 50, percentageY: 50, crop: {} };
            }
            if (!image.colorSpace) {
                image.colorSpace = 'sRGB';
            }
            if (state.ids.indexOf(image.id) > -1) {
                return state;
            }

            if (tempIds.indexOf(image.id) === -1) {
                tempIds.push(image.id);
            }
            const res = {
                ids: tempIds,
                entities: Object.assign({}, state.entities, {
                    [image.id]: image
                }),
                oldEntities: { ...state.entities }
            };
            return res;
        case ImageActions.LOAD_IMAGE_BY_ID:
            let imageId: string = action.payload;

            if (tempIds.indexOf(imageId) === -1) {
                tempIds.push(imageId);
            }
            const response = {
                ids: tempIds,
                entities: Object.assign({}, state.entities, {
                    [action.payload.id]: action.payload
                }),
                oldEntities: { ...state.entities }
            };
            return response;
        case ImageActions.UPDATE_IMAGE_SUCCESS:
            const payload: any = { ...action.payload };
            let tempImagePayload = state.oldEntities[payload.id];

            if (tempImagePayload == null || tempImagePayload.imageCropResolutions == null) {
                tempImagePayload = state.entities[payload.id];
            }

            if (tempIds.indexOf(payload.id) === -1) {
                tempIds.push(payload.id);
            }

            if (payload.empty == true) {
                return {
                    ids: tempIds,
                    entities: {},
                    oldEntities: { ...state.entities }
                };
            } else if (payload.empty == false) {
                return {
                    ids: tempIds,
                    entities: { ...state.oldEntities },
                    oldEntities: { ...state.oldEntities }
                };
            } else if (payload.reset === true) {
                return {
                    ids: tempIds,
                    entities: {
                        ...Object.assign({}, state.entities, {
                            [payload.id]: {}
                        })
                    },
                    oldEntities: { ...state.entities }
                }
            }
            if (payload.nlCleanUrl != null) {
                tempImagePayload.nlCleanUrl = payload.nlCleanUrl;
            }
            if (payload.priority != null) {
                tempImagePayload.priority = payload.priority;
            }
            if (payload.tags != null) {
                tempImagePayload.tags = payload.tags;
                const priorityObject: any = {};
                for (const tag of tempImagePayload.tags) {
                    if (tempImagePayload.priority == null) {
                        tempImagePayload.priority = {};
                    }
                    priorityObject[tag.id] = tempImagePayload.priority[tag.id] || null;
                }
                tempImagePayload.priority = priorityObject;
            }
            return {
                ids: tempIds,
                entities: Object.assign({}, state.entities, {
                    [payload.id]: tempImagePayload
                }),
                oldEntities: { ...state.entities }
            }
        case ImageActions.CHANGE_IMAGE_PRIORITY_SUCCESS:
            const newImage = state.entities[action.payload.id];

            if (tempIds.indexOf(action.payload.id) === -1) {
                tempIds.push(action.payload.id);
            }

            return {
                ids: tempIds,
                entities: Object.assign({}, state.entities, {
                    [action.payload.id]: newImage
                }),
                oldEntities: { ...state.entities }
            }
        case ImageActions.ADD_IMAGE_TAG:
            if (tempIds.indexOf(action.payload.image.id) === -1) {
                tempIds.push(action.payload.image.id);
            }
            return {
                ids: tempIds,
                entities: Object.assign({}, state.entities, {
                    [action.payload.image.id]: action.payload.image
                }),
                oldEntities: { ...state.entities }
            }
        case ImageActions.UPDATE_IMAGE_EDIT:
            const updatedImage = { ...action.payload.image };

            const tempUpdateProcess = { ...updatedImage.process };
            const tempUpdateUploadProcess = { ...tempUpdateProcess.upload };
            const tempUpdateCropProcess = { ...tempUpdateProcess.crop };
            tempUpdateUploadProcess.done = null;
            tempUpdateCropProcess.done = null;
            tempUpdateProcess.upload = tempUpdateUploadProcess;
            tempUpdateProcess.crop = tempUpdateCropProcess;
            updatedImage.process = tempUpdateProcess;

            return {
                ids: [...state.ids],
                entities: Object.assign({}, state.entities, {
                    [updatedImage.id]: updatedImage
                }),
                oldEntities: { ...state.entities }
            }
        case ImageActions.UPDATE_IMAGE_EDIT_SUCCESS:
            const successImage = state.entities[action.payload.id];

            const tempSuccessProcess = { ...successImage.process };
            const tempSuccessUploadProcess = { ...tempSuccessProcess.upload };
            const tempSuccessCropProcess = { ...tempSuccessProcess.crop };
            tempSuccessUploadProcess.done = new Date();
            tempSuccessCropProcess.done = new Date();
            tempSuccessProcess.upload = tempSuccessUploadProcess;
            tempSuccessProcess.crop = tempSuccessCropProcess;
            successImage.process = tempSuccessProcess;

            return {
                ids: [...state.ids],
                entities: Object.assign({}, state.entities, {
                    [successImage.id]: successImage
                }),
                oldEntities: { ...state.entities }
            }
        default:
            return state;
    }
};

export function getImageEntities(): any {
    return (state$: Store<ImageState>) => state$
        .select(s => s.entities);
};

export function getImage(id: string) {
    return (state$: Store<ImageState>) => state$
        .select(s => s.entities[id]);
}

export function getImages(ImageIds: string[]) {
    return (state$: Store<ImageState>) => state$
        .pipe(getImageEntities(), map((entities: any) => ImageIds.map(id => entities[id])));
}

export function hasImage(id: string) {
    return (state$: Store<ImageState>) => state$
        .select(s => (s.ids.indexOf(id) > 0));
}
