import { Injectable } from '@angular/core';

import { Apollo } from 'apollo-angular';
import { gql } from 'graphql-tag';
import { HelperService } from './helper.service';

@Injectable({
    providedIn: 'root'
})
export class TagGraphqlService {
    constructor(
        private apollo: Apollo,
        private helperService: HelperService
    ) { }

    getTags(bookmark: string) {
        const tempSearchObj: any = {
            bookmark,
            limit: 100
        }
        const apolloQuery = gql`
            query tags($searchObj: ExtraTagParams) {
                tags(eparams: $searchObj) {
                    code
                    bookmark
                    total
                    tags {
                        id
                        className
                    }
                    success
                }
            }
        `;
        return this.apollo.query({
            query: apolloQuery,
            variables: { searchObj: tempSearchObj }
        });
    }

    searchTags(searchQuery: string, bookmark: string = null) {
        const replacedSearchQuery = searchQuery.replace(/\"/g, '');
        let cleaned = this.helperService.cleanupForwardSlash(replacedSearchQuery.trim());
        cleaned = this.helperService.cleanupMinus(cleaned);
        cleaned = this.helperService.cleanupQuery(cleaned);
        const tempSearchObj: any = {
            bookmark: bookmark,
            limit: 100
        }
        const apolloQuery = gql`
            query searchTags($searchObj: ExtraTagParams) {
                searchTags(eparams: $searchObj, keyword: "${cleaned}") {
                    code
                    tags {
                        id
                        className
                    }
                    total
                    bookmark
                    errors {
                        message
                        type
                    }
                    success
                }
            }
        `;
        return this.apollo.query({
            query: apolloQuery,
            variables: { searchObj: tempSearchObj }
        });
    }

    addTag(data: any) {
        const apolloQuery = gql`
            mutation {
                addTag(name: "${data.id}", className: "${data.className}") {
                    tag {
                        id
                        name
                        className
                    }
                    code
                    success
                    errors {
                        message
                        type
                    }
                }
            }
        `;
        return this.apollo.mutate({
            mutation: apolloQuery,
        });
    }

    updateTag(data: any) {
        const apolloQuery = gql`
            mutation {
                updateTag(id: "${data.oldId}", name: "${data.id}", className: "${data.className}") {
                    tag {
                        id
                        name
                        className
                    }
                    code
                    success
                    errors {
                        message
                        type
                    }
                }
            }
        `;
        return this.apollo.mutate({
            mutation: apolloQuery,
        });
    }

    deleteTag(data: any) {
        const apolloQuery = gql`
        mutation {
            deleteTag(id: "${data.id}") {
                tag {
                    id
                    name
                    className
                }
                code
                success
                errors {
                    message
                    type
                }
            }
        }
        `;
        return this.apollo.mutate({
            mutation: apolloQuery,
        });
    }
}
