import { Injectable } from '@angular/core';

import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { filter, map, switchMap, catchError, mergeMap, concatMap } from 'rxjs/operators';

import { ImageService } from '@services/image.service';
import { ImageActions } from '@actions/image.actions';
import { ActionWithPayload } from '@models/action-with-payload';
import { ImageGraphqlService } from '@services/image-graphql.service';

@Injectable()
export class ImageEffects {
    search$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ImageActions.SEARCH_IMAGE),
            filter((action: ActionWithPayload) => action.payload !== ''),
            switchMap((action: ActionWithPayload) => this.imageGraphqlService.getImages(action.payload).pipe(
                map((searchResult: any) => {
                    if (searchResult.data.images != null && searchResult.data.images.errors == null) {
                        const tempObj = {
                            data: searchResult.data.images.image,
                            sort: action.payload.sortType,
                            bookmark: searchResult.data.images.bookmark,
                            total: searchResult.data.images.total
                        }
                        return this.imageActions.searchImageSuccess(tempObj)
                    } else {
                        return this.imageActions.searchImageFailed(searchResult.data.images.errors[0]);
                    }
                }),
                catchError((err) => of(this.imageActions.searchImageFailed(err)))
            ))
        );
    });

    searchNextPage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ImageActions.SEARCH_IMAGE_NEXTPAGE),
            switchMap((action: ActionWithPayload) => this.imageGraphqlService.getImages(action.payload).pipe(
                map((searchResult: any) => {
                    if (searchResult.data.images != null && searchResult.data.images.errors == null) {
                        const tempObj = {
                            data: searchResult.data.images.image,
                            sort: action.payload.sortType,
                            bookmark: searchResult.data.images.bookmark
                        }
                        return this.imageActions.searchImageNextPageSuccess(tempObj)
                    } else {
                        return this.imageActions.searchImageNextPageFailed(searchResult.data.images.errors[0]);
                    }
                }),
                catchError((err) => of(this.imageActions.searchImageNextPageFailed(err)))
            ))
        );
    });

    loadImage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ImageActions.LOAD_IMAGE_BY_ID),
            switchMap((action: ActionWithPayload) => this.imageGraphqlService.retrieveImage(action.payload).pipe(
                map((loadImage: any) => {
                    return loadImage
                }),
            ))
        );
    });

    update$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ImageActions.UPDATE_IMAGE),
            filter((action: ActionWithPayload) => action.payload !== ''),
            switchMap((action: ActionWithPayload) => this.imageGraphqlService.updateImage(action.payload)),
            map(result => this.imageActions.updateImageSuccess(result))
        );
    });

    update_properties$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ImageActions.UPDATE_IMAGE_PROPERTIES),
            filter((action: ActionWithPayload) => action.payload !== ''),
            switchMap((action: ActionWithPayload) => this.imageGraphqlService.updateImageProperties(action.payload)),
            map((result: any) => this.imageActions.updateImageSuccess(result))
        );
    });

    update_focuspoint$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ImageActions.UPDATE_IMAGE_FOCUSPOINT),
            filter((action: ActionWithPayload) => action.payload !== ''),
            switchMap((action: ActionWithPayload) => this.imageGraphqlService.updateImageFocuspoint(action.payload)),
            map((result: any) => this.imageActions.updateImageSuccess(result))
        );
    });

    update_cleanurl$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ImageActions.UPDATE_IMAGE_NLCLEANURL),
            filter((action: ActionWithPayload) => action.payload !== ''),
            switchMap((action: ActionWithPayload) => this.imageGraphqlService.updateImageCleanurl(action.payload)),
            map((result: any) => this.imageActions.updateImageSuccess(result))
        );
    });

    update_edit$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ImageActions.UPDATE_IMAGE_EDIT),
            filter((action: ActionWithPayload) => action.payload !== ''),
            switchMap((action: ActionWithPayload) => this.imageGraphqlService.reuploadEditedImage(action.payload)),
            map(result => this.imageActions.updateImageEditSuccess(result))
        );
    });

    addTag$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ImageActions.ADD_IMAGE_TAG),
            filter((action: ActionWithPayload) => action.payload !== ''),
            mergeMap((action: ActionWithPayload) => this.imageGraphqlService.addImageTag(action.payload.image, action.payload.tag).pipe(
                concatMap((result: any) => {
                    const tempImage = { ...result.data.addImageTag.image };
                    tempImage.reset = true;

                    const tempResultPayload = { ...tempImage };
                    delete tempResultPayload.reset;

                    // Return an array of actions to be dispatched
                    return [
                        this.imageActions.updateImageSuccess(tempImage),
                        this.imageActions.updateImageSuccess(tempResultPayload)
                    ];
                })
            ))
        );
    });

    changePriority$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ImageActions.CHANGE_IMAGE_PRIORITY),
            filter((action: ActionWithPayload) => action.payload !== ''),
            switchMap((action: ActionWithPayload) => this.imageGraphqlService.changeImagePriority(action.payload.image, action.payload.tag, action.payload.priority)),
            map((result: any) => this.imageActions.changeImagePrioritySuccess(result.data.updatePriority.image))
        );
    });

    addRecognizedImageTag$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ImageActions.ADD_RECOGNIZED_IMAGE_TAG),
            filter((action: ActionWithPayload) => action.payload !== ''),
            switchMap((action: ActionWithPayload) => this.imageService.addRecognizedImageTag(action.payload.image, action.payload.tag)),
            map(result => this.imageActions.addImageTag(result.image, result.tag))
        );
    });

    deleteTag$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ImageActions.DELETE_IMAGE_TAG),
            filter((action: ActionWithPayload) => action.payload !== ''),
            switchMap((action: ActionWithPayload) => this.imageGraphqlService.deleteImageTag(action.payload.image, action.payload.tag)),
            map((result: any) => this.imageActions.updateImageSuccess(result.data.deleteImageTag.image))
        );
    });

    deleteCognitiveTag$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ImageActions.DELETE_COGNITIVE_TAG),
            filter((action: ActionWithPayload) => action.payload !== ''),
            switchMap((action: ActionWithPayload) => this.imageGraphqlService.updatecognitiveRecognizeImage(action.payload.image.id, action.payload.image.recognitionClasses)),
            map((result: any) => this.imageActions.updateImageSuccess(result))
        );
    });

    trashImage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ImageActions.TRASH_IMAGE),
            filter((action: ActionWithPayload) => action.payload !== ''),
            switchMap((action: ActionWithPayload) => this.imageGraphqlService.trashImage(action.payload.id)),
            map((result: any) => this.imageActions.updateImageSuccess(result))
        );
    });

    cognitiveRecognizeImage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ImageActions.COGNITIVE_RECOGNIZE_IMAGE),
            filter((action: ActionWithPayload) => action.payload !== ''),
            switchMap((action: ActionWithPayload) => {
                return this.imageGraphqlService.startCognitiveRecognizeImage(action.payload)
            }),
            map((result: any) => this.imageActions.cognitiveRecognizeImageSuccess(result))
        );
    });

    constructor(
        private imageService: ImageService,
        private imageGraphqlService: ImageGraphqlService,
        private actions$: Actions,
        private imageActions: ImageActions,
    ) { }
};
