import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { Actions, ofType, createEffect } from '@ngrx/effects';

import { CropresolutionGraphqlService } from '@services/cropresolution-graphql.service';
import { CropResolutionActions } from '@actions/cropresolution.actions';

@Injectable()
export class CropResolutionEffects {
  constructor(
    private cropResolutionService: CropresolutionGraphqlService,
    private actions$: Actions,
    private cropResolutionActions: CropResolutionActions,
  ) { }

  search$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CropResolutionActions.FETCH_CROPRESOLUTIONS),
      switchMap(
        () => this.cropResolutionService.getCropresolutions().pipe(
          map(
            (searchResult: any) => {
              if (searchResult.data.cropresolutions != null && searchResult.data.cropresolutions.errors == null) {
                const tempObj = {
                  data: searchResult.data.cropresolutions.cropresolution
                }
                return this.cropResolutionActions.fetchCropResolutionsSuccess(tempObj);
              } else {
                return this.cropResolutionActions.fetchCropResolutionsFailed(searchResult.data.cropresolutions.errors[0]);
              }
            }
          ),
          catchError((err) => of(this.cropResolutionActions.fetchCropResolutionsFailed(err)))
        )
      )
    )
  );
};
