import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { TagActions } from '../actions/tag.actions';
import { TagService } from '../services/tag.service';
import { TagGraphqlService } from '../services/tag-graphql.service';
import { of } from 'rxjs';
import { filter, map, switchMap, catchError } from 'rxjs/operators';

import { ActionWithPayload } from '../models/action-with-payload';

@Injectable()
export class TagEffects {
    search$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TagActions.SEARCH_TAG),
            filter((action: ActionWithPayload) => action.payload !== '')
            , switchMap((action: ActionWithPayload) => this.tagGraphqlService.searchTags(action.payload)
                .pipe(
                    map((searchResult: any) => {
                        if (searchResult.data.searchTags != null && searchResult.data.searchTags.errors == null) {
                            const tempObj = {
                                data: searchResult.data.searchTags.tags,
                                bookmark: searchResult.data.searchTags.bookmark
                            }
                            return this.tagActions.searchTagSuccess(tempObj);
                        } else {
                            return this.tagActions.searchTagFailed(searchResult.data.searchTags.errors[0]);
                        }
                    })
                    , catchError((err) => of(this.tagActions.searchTagFailed(err)))))
        )
    );

    // TODO: Check if this is still used somewhere
    // add$ = createEffect(() =>
    //   this.actions$.pipe(
    //     ofType(TagActions.ADD_TAG),
    //     filter((action: ActionWithPayload) => action.payload !== '')
    //     , switchMap((action: ActionWithPayload) => this.tagService.add(action.payload)
    //       .pipe(map(response => this.tagActions.addTagSuccess(response))
    //         , catchError((err) => of(this.tagActions.addTagFailed(err)))))
    //   )
    // );

    update$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TagActions.UPDATE_TAG),
            filter((action: ActionWithPayload) => action.payload !== null)
            , switchMap((action: ActionWithPayload) => this.tagGraphqlService.updateTag(action.payload)
                .pipe(map(response => this.tagActions.updateTagSuccess(response))
                    , catchError((err) => of(this.tagActions.updateTagFailed(err)))))
        )
    );

    // TODO: Reimplement this feature once the API is working
    // merge$ = createEffect(() =>
    //   this.actions$.pipe(
    //     ofType(TagActions.MERGE_TAG),
    //     filter((action: ActionWithPayload) => action.payload !== null)
    //     , switchMap((action: ActionWithPayload) => this.tagService.merge(action.payload)
    //       .pipe(map(response => this.tagActions.mergeTagSuccess(response))
    //         , catchError((err) => of(this.tagActions.mergeTagFailed(err)))))
    //   )
    // );

    delete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TagActions.DELETE_TAG),
            filter((action: ActionWithPayload) => action.payload !== null)
            , switchMap((action: ActionWithPayload) => this.tagGraphqlService.deleteTag(action.payload)
                .pipe(map(response => this.tagActions.deleteTagSuccess(response))
                    , catchError((err) => of(this.tagActions.deleteTagFailed(err)))))
        )
    );

    constructor(
        private tagService: TagService,
        private tagGraphqlService: TagGraphqlService,
        private actions$: Actions,
        private tagActions: TagActions,
    ) { }
};
