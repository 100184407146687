import { SelectedImageActions } from '@actions/selectedimage.actions';
import { SelectedImageModel } from '@models/selectedimage';
import { ActionWithPayload } from '@models/action-with-payload';

const initialState: SelectedImageModel = {
    focusPoint: {
        percentageY: 50,
        percentageX: 50
    }
};

export function SelectedImageReducer(state: SelectedImageModel = initialState, action: ActionWithPayload): SelectedImageModel {
    switch (action.type) {
        case SelectedImageActions.UPDATE_SELECTED_IMAGE:
            state = { ...action.payload };
            return state;
        default:
            return state;
    }
};
