import { NgModule } from '@angular/core';
import { APOLLO_NAMED_OPTIONS, ApolloModule, NamedOptions } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { ApolloLink, InMemoryCache } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';

const auth = setContext(() => {
    const token = localStorage.getItem('id_token');
    if (token === null) {
        return {};
    } else {
        return {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
    }
});

export function createApollo(httpLink: HttpLink): NamedOptions {
    const defaultLink = ApolloLink.from([
        auth,
        httpLink.create({
            uri: 'https://api.travelspirit.cloud/graphql'
        })
    ]);

    return {
        default: {
            link: defaultLink,
            cache: new InMemoryCache({
                addTypename: false
            })
        }
    };
}

@NgModule({
    exports: [ApolloModule],
    providers: [
        {
            provide: APOLLO_NAMED_OPTIONS,
            useFactory: createApollo,
            deps: [HttpLink],
        },
    ],
})
export class GraphQLModule { }
