import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

import { TagActions } from '@actions/tag.actions';
import { TagModel } from '@models/tag';
import { TagFulltextSearchResultModel } from '@models/tagfulltextsearchresult';
import { ActionWithPayload } from '@models/action-with-payload';

export interface TagState {
    ids: string[];
    entities: { [id: string]: TagModel };
}

const initialState: TagState = {
    ids: [],
    entities: {}
};

export function TagReducer(state: TagState = initialState, action: ActionWithPayload): TagState {
    switch (action.type) {
        case TagActions.SEARCH_TAG_SUCCESS:
            const searchResult: TagFulltextSearchResultModel = action.payload;

            let resultInfo = searchResult.data.reduce((resultInfo: any, row: any) => {
                const document: any = { ...row };
                document.changed = false;
                resultInfo.ids.push(row.id);
                resultInfo.entities[row.id] = document;
                return resultInfo;
            }, { ids: [], entities: {} });

            return {
                ids: resultInfo.ids,
                entities: resultInfo.entities
            };
        case TagActions.LOAD_TAG:
            const tag: TagModel = action.payload;
            if (state.ids.indexOf(tag.id) > 0) {
                return state;
            }
            return {
                ids: [...state.ids, tag.id],
                entities: Object.assign({}, state.entities, {
                    [tag.id]: tag
                })
            };
        default:
            return state;
    }
};

export function getTagEntities(): any {
    return (state$: Store<TagState>) => state$
        .select(s => s.entities);
};

export function getTag(id: string) {
    return (state$: Store<TagState>) => state$
        .select(s => s.entities[id]);
}

export function getTags(TagIds: string[]) {
    return (state$: Store<TagState>) => state$
        .pipe(getTagEntities(), map((entities: any) => TagIds.map(id => entities[id])));
}

export function hasTag(id: string) {
    return (state$: Store<TagState>) => state$
        .select(s => (s.ids.indexOf(id) > 0));
}
