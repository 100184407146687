import { Injectable } from '@angular/core';

import { Apollo } from 'apollo-angular';
import { gql } from 'graphql-tag';

@Injectable({
  providedIn: 'root'
})
export class CropresolutionGraphqlService {
  constructor(
    private apollo: Apollo
  ) { }

  getCropresolutions() {
    const apolloQuery = gql`
      {
        cropresolutions {
          code
          success
          errors {
            message
            type
          }
          cropresolution {
            id
            description
            ratio
            width
            quality
            path
            type
            url
          }
        }
      }
    `;
    return this.apollo.query({
      query: apolloQuery,
    });
  }
}
