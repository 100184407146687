import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor() { }

  cleanupForwardSlash(query: any) {
    if (query != null && query.length > 0) {
      query = query.trim();
    }
    if (query.indexOf('/') > -1) {
      const cleanedUp = query.split('/').reduce((a: any, b: any) => {
        if (b.trim().length > 0) {
          a.push(b.trim());
        }
        return a;
      }, []);
      return cleanedUp;
    } else {
      return this.escapeQuery(query);
    }
  }

  cleanupMinus(query: any) {
    if (query[0] != null && query[0].length > 1) {
      const queryArray = [];
      for (let string of query) {
        string = string.trim();
        if (string.indexOf('-') > -1) {
          let cleanedUp;
          if (string[string.indexOf('-') - 1] != null && string[string.indexOf('-') - 1] === ' ') {
            cleanedUp = string.split(' ').reduce((a: any, b: any) => {
              if (b.trim().length > 0) {
                a.push(b.trim() + '*');
              }
              return a;
            }, []);
          } else {
            cleanedUp = string.split('-').reduce((a: any, b: any) => {
              if (b.trim().length > 0) {
                a.push(b.trim() + '*');
              }
              return a;
            }, []);
          }
          if (cleanedUp.length > 2 && cleanedUp[cleanedUp.length - 1] === '*' && cleanedUp[cleanedUp.length - 2] === '*') {
            cleanedUp = cleanedUp.substring(0, cleanedUp.length - 1);
          }
          for (const part of cleanedUp) {
            queryArray.push(part);
          }
          return queryArray.join(' AND ');
        } else {
          queryArray.push(string + '*');
        }
      }
      return queryArray.join(' AND ');
    } else {
      if (query != null && query.length > 0) {
        query = query.trim();
      }
      if (query.indexOf('-') > -1) {
        let cleanedUp;
        if (query[query.indexOf('-') - 1] != null && query[query.indexOf('-') - 1] === ' ') {
          cleanedUp = query.split(' ').reduce((a: any, b: any) => {
            if (b.trim().length > 0) {
              a.push(b.trim() + '*');
            }
            return a;
          }, []).join(' AND ');
        } else {
          cleanedUp = query.split('-').reduce((a: any, b: any) => {
            if (b.trim().length > 0) {
              a.push(b.trim() + '*');
            }
            return a;
          }, []).join(' AND ');
        }
        if (cleanedUp.length > 2 && cleanedUp[cleanedUp.length - 1] === '*' && cleanedUp[cleanedUp.length - 2] === '*') {
          cleanedUp = cleanedUp.substring(0, cleanedUp.length - 1);
        }
        return cleanedUp;
      } else {
        return this.escapeQuery(query) + '*';
      }
    }
  }

  cleanupQuery(query: string) {
    if (query.indexOf(' **') > -1) {
      query = query.replace(' **', '');
    }
    if (query.indexOf('**') > -1) {
      query = query.replace('**', '*');
    }
    if (query === '*') {
      query = query.replace('*', '');
    }
    if (query === ' ') {
      query = query.replace(' ', '');
    }
    return query;
  }

  escapeQuery(query: string) {
    let escapedQuery = query.replace(/([\!\*\+\&\|\(\)\[\]\{\}\^\~\?\:\"\/])(?=(?:[^"]|"[^"]*")*$)/g, '\\$1');
    return escapedQuery;
  }

  cleanupTagSearch(query: string) {
    let tagQuery = '*:*';
    if (query.length > 0) {
      if (query.indexOf('"') === -1 && query.indexOf('*') === -1 && query.indexOf('+') === -1 && query.indexOf('(') === -1 && query.indexOf(')') === -1) {
        let valArr = query.split(' ');
        valArr = valArr.filter(v => v != '').map((v: any) => {
          let cleaned = this.cleanupForwardSlash(v.trim());
          cleaned = this.cleanupMinus(cleaned);
          cleaned = this.cleanupQuery(cleaned);
          return cleaned;
        });
        tagQuery = 'name:(' + valArr.join(' AND ') + ')';
      } else {
        tagQuery = 'name:(' + query + ')';
      }
    } else {
      tagQuery = 'name:(' + tagQuery + ')';
    }
    return tagQuery;
  }
}
