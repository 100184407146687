import { CropResolutionActions } from '@actions/cropresolution.actions';
import { CropResolutionModel } from '@models/cropresolution';
import { CropResolutionResultModel } from '@models/cropresolutionresult';
import { ActionWithPayload } from '@models/action-with-payload';

export interface CropResolutionState {
  ids: string[];
  entities: { [id: string]: CropResolutionModel };
}

const initialState: CropResolutionState = {
  ids: [],
  entities: {}
};

export function CropResolutionReducer(state: CropResolutionState = initialState, action: ActionWithPayload): CropResolutionState {
  switch (action.type) {
    case CropResolutionActions.FETCH_CROPRESOLUTIONS:
      return Object.assign({}, state, {
        loading: true
      });
    case CropResolutionActions.FETCH_CROPRESOLUTIONS_SUCCESS:
      const searchResult: CropResolutionResultModel = action.payload;
      const resultInfo = searchResult.data.reduce((resultInfo: any, row) => {
        resultInfo.ids.push(row.id);
        if (row.url != null) {
            resultInfo.entities[row.id] = row;
        }
        return resultInfo;
      }, { ids: [], entities: {} });

      if (Object.keys(resultInfo.entities).length > 0) {
        state.entities = resultInfo.entities;
        state.ids = resultInfo.ids;
      }
      return state;
    case CropResolutionActions.FETCH_CROPRESOLUTIONS_FAILED:
      return initialState;
    default:
      return state;
  }
};
